<!--
 * @Author: your name
 * @Date: 2021-01-28 19:42:51
 * @LastEditTime: 2024-09-26 14:25:22
 * @LastEditors: hl
 * @Description: In User Settings Edit
 * @FilePath: /azg/src/views/newIndex/components/WapFromWorld.vue
-->
<template>
  <div
    class="wap_from_con"
    :class="$t('languages') == 'EN' ? '' : 'zh_from_con_font'"
  >
    <div
      v-if="title"
      class="wapfont20 font500w title_1"
      :class="$t('languages') == 'EN' ? '' : 'zh_from_con_font'"
    >
      {{ title }}
    </div>
    <div v-if="details" class="wapfont14 font300w details">{{ details }}</div>
    <div v-if="details1" class="wapfont14 font300w">{{ details1 }}</div>
    <div v-if="more" class="wapfont14 font300w more_know">
      <span>{{ more }}</span>
      <img
        src="@/assets/img/index/go_more1@2x.png"
        alt=""
        @click="goPath(path)"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    details: {
      type: String,
      default: "",
    },
    details1: {
      type: String,
      default: "",
    },
    more: {
      type: String,
      default: "",
    },
    path: {
      type: String,
      default: "",
    },
  },
  methods: {
    goPath(path) {
      if (
        (!localStorage.getItem("AZG_TOKEN") ||
          localStorage.getItem("AZG_TOKEN") === null) &&
        path !== "/companyintr"
      ) {
        this.$router.push("/login");
        return;
      }
      this.$router.push(path);
    },
  },
};
</script>

<style scoped lang="less">
.wap_from_con {
  // background: #c4c4c4;
  margin: calc(100vw * 5.6 / 37.5) 0 0;
  // font-family: FZLanTingYuanS-EL-GB;
  .title_1 {
    color: #bf9264;
    margin-bottom: calc(100vw * 1.6 / 37.5);
    // font-family: FZLanTingYuanS-DB-GB;
  }
  .more_know {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: calc(100vw * 1.8 / 37.5);
    img {
      display: block;
      width: calc(100vw * 2.4 / 37.5);
      margin-left: calc(100vw * 0.8 / 37.5);
    }
  }
  .details {
    padding: 0 20px;
    // text-align: left;
  }
}
.zh_from_con_font {
  font-family: FZLanTingYuanS-EL-GB;
}
.zh_title_1_font {
  font-family: FZLanTingYuanS-DB-GB;
}
</style>
